<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ appName }}
            </h2>
          </router-link>
        </v-card-title>

        <!-- TODO: copy -->
        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            SIGN IN
          </p>
          <p class="mb-2">
            {{ showPinInput ? "Please put your email and pin." : "Please put your email to get a pin." }}
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form
            ref="loginForm"
            @submit.prevent="handleFormSubmit"
          >
            <v-text-field
              v-model="email"
              outlined
              :disabled="showPinInput"
              label="Email"
              placeholder="email"
              name="email"
              :error-messages="errorMessages.email"
              :rules="[validators.required, validators.emailValidator]"
              hide-details="auto"
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-if="showPinInput"
              v-model="password"
              outlined
              autofocus
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Pin"
              placeholder="······"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details="auto"
              :rules="[validators.required, validators.pinLength]"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <v-btn
              block
              color="primary"
              class="mt-6"
              type="submit"
              :loading="loading"
              :disabled="loading"
            >
              {{ showPinInput ? "Login" : "Get pin" }}
            </v-btn>
          </v-form>
          <br>

          <v-alert
            v-if="errorMessages.length > 0"
            text
            color="error"
          >
            {{ errorMessages }}
          </v-alert>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, getCurrentInstance } from '@vue/composition-api'
import { required, emailValidator, pinLength } from '@core/utils/validation'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'

export default {
  setup() {
    // Template Ref
    const loginForm = ref(null)
    const showPinInput = ref(false)

    const loading = ref(false)

    const vm = getCurrentInstance().proxy
    const { router } = useRouter()
    const isPasswordVisible = ref(false)

    const email = ref(null)
    const password = ref(null)
    const errorMessages = ref([])
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    const handleFormSubmit = () => {
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return

      loading.value = true

      if (!showPinInput.value) {
        vm.$http
          .post('/sign-in', { email: email.value })
          .then(() => {
            showPinInput.value = true
            errorMessages.value = []
          })
          .catch(error => {
            if (error.response) {
              errorMessages.value = error?.response?.data?.message
              if (errorMessages.value === 'Pin was already sent!') showPinInput.value = true
            }
            if (error.response === undefined) vm.$toastr.e(error)
          })
          .then(() => {
            loading.value = false
          })
      } else {
        vm.$http
          .post('/verify', { email: email.value, pin: password.value })
          .then(response => {
            vm.$userService.fillStorage(response.data)
            // vm.saveUsersData(response)

            errorMessages.value = []
            // On success redirect to home
            router.push({ name: 'dashboard' })
          })
          .catch(error => {
            if (error.response) {
              errorMessages.value = error?.response?.data?.message
            }
            if (error.response === undefined) vm.$toastr.e(error)
          })
          .then(() => {
            loading.value = false
          })
      }
    }

    return {
      handleFormSubmit,
      showPinInput,

      isPasswordVisible,
      email,
      password,
      errorMessages,
      socialLink,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
        pinLength,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      loginForm,

      loading,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
